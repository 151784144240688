.navbar {
  background: #00745f;
  display: flex;
}

.navbar-logo {
  margin-top: 0;
  width: 100px;
  height: 80px;
}
.navbar img {
  width: 100%;
  height:90px;
  margin-top: -5px;
  margin-left: 25px;
}

.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 15px;

  list-style: none;
  color: #fff;
}

.nav-menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}
.menu-icon {
  display: none;
  color: #fff;
}

@media screen and (max-width: 1080px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 95px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .nav-menu.active {
    background: #00745f;
    width: 100%;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-menu li a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
  }
}
