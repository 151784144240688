.portfolio {
  justify-content: center;
  text-align: center;
}
.portfolio h2{
  margin-top: 15px;
  color: yellowgreen;
}

.portfolio p {
   text-align: justify;
  font-size: 18px;
}

.team p{
  text-align: center;
}

.p-header{
  margin: 20px 75px;
}

.portfolio ul {
  justify-content: center;
  text-align: center;
  display: flex;
}
.portfolio h3 {
  color: goldenrod;
  margin: 25px;
}

.portfolio img {
  border-radius: 10px;
  width: 350px;
  margin: 25px;
}
.frequenty {
  padding: 20px;
}

.f1 {
  display: flex;
}
.f1 p {
  margin: 20px;
  padding: 15px;
}

.fa-question-circle {
  color: goldenrod;
  font-size: 15px;
  padding: 5px;
}
@media (max-width: 1112px) {
  .f1 {
    flex-direction: column;
  }
}

@media (max-width: 720px) {
  .portfolio p {
    margin: 0 20px;
  }
  .portfolio h3 {
    margin: 15px;
  }
  .f1 {
    flex-direction: column;
  }
  .p-header{
    margin: 20px 25px;
  }
   
}

@media (max-width: 420px) {
  .portfolio img {
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 25px 0px;
  }
  .portfolio ul {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
  }
   
   
}
