.feature {
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-color: wheat; */
  padding: 15px;
}
.feature h1 {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: green;
  font-weight: 500;
}
.f-p {
  margin: 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.f-mobile-app {
  margin-top: 20px;
  display: flex;
  padding: 60px 100px;
}

.mobile img {
  width: 500px;
  height: 100%;
}

.feature ul li {
  list-style: none;
}

.feature li {
  margin: 20px;
}
.feature li i {
  margin: 10px;
}
.fa-arrow-right {
  color: yellowgreen;
}

.feature p {
  font-size: 17px;
  text-align: justify;
}

/***** feature-2 ********/

.feature-2 {
  display: flex;
  padding: 20px 100px;
}

.f2-saas img {
  width: 450px;
  padding-right: 80px;
}

/***** feature-3 ********/

.f3 {
  display: flex;
  padding: 20px 100px;
}

.f3-web img {
  width: 450px;
  padding-left: 80px;
}

/***** feature-4 ********/

.f4 {
  display: flex;
  padding: 20px 100px;
}

.f4-dev img {
  width: 450px;
}

@media (max-width: 1080px) {
  .f-mobile-app {
    flex-direction: column;
    padding: 0;
  }
  .mobile {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .mobile img {
    margin-top: 0;
    height: 400px;
  }
  .feature img {
    width: 600px;
    height: 550px;
  }
  .feature p {
    margin: 10px 40px;
 }

  /****** feature-2 ******/

  .feature-2 {
    flex-direction: column-reverse;
    padding: 0;
  }
  .f2-info {
    padding: 0;
  }

  /****** feature-3 ******/

  .f3 {
    flex-direction: column;
    padding: 0;
  }
  /****** feature-4 ******/

  .f4 {
    padding: 0;
    flex-direction: column-reverse;
  }
}

@media (max-width: 720px) {
  .feature img {
    width: 300px;
    height: 300px;
  }
  .feature p {
     margin: 10px 20px;
  }
}
