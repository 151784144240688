.about {
  justify-content: center;
  
}
.a-header{
  background-color: #00745f;
}
.a-header h2{
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 65px;
  font-weight: 300;
  color: #fff;
}

.about p{
  margin: 20px 50px;
}
.a-container{
  display: flex;
  flex-direction: column;
}
.a-left {
  margin: 20px;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.a-left img {
  width: 75%;
  height: 400px;
}

.a-right {
  margin: 15px;
  padding: 20px 50px;
}
.a-line {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
}

.r-build h1{
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #213b52;
}
.r-build samp{
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.a-info h3{
  color: #213b52;
}

.a-info samp{
  font-size: 15px;
}

 

 
.a-i {
  display: flex;
}
 
.a2 {
  display: flex;
  margin-top: 40px;
}

.erp-design {
  margin-left: 100px;
}

@media (max-width: 1080px) {
  .a-container {
    flex-direction: column;
  }
  .a-left {
    margin: 0;
  }
  .a-right{
    margin:  20px 45px;
  }

  .a-left img {
    border-radius: 5px;
    margin: 20px;
    width: 80%;
    height: 500px;
  }
  .a-info h3 {
    font-size: 25px;
  }
}

@media (max-width: 720px) {
  .a-left img {
    margin: 20px;
    width: 90%;
    height: 400px;
  }
  .a-i {
    flex-direction: column;
  }
  .a2 {
    flex-direction: column;
    margin: 0;
  }
  .a-info h3{
     justify-content: center;
     align-items: center;
     text-align: center;
  }
  .a-right {
    padding: 0;
    margin: 10px 25px;
  }
  .erp-design {
    margin-left: 0;
  }
  .design h3{
    margin-top: 20px;
  }
  .erp-design h3{
    margin-top: 20px;
  }
}

@media (max-width:540px) {
  .about p{
    margin: 20px 20px;
  }
}