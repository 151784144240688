.footer {
  background-color: #00745f;
  /* background-image: url("../../assets/footer_bg.jpg"); */
  padding: 25px;
  padding-top: 50px;
  color: #fff;
}
.footer img {
  width: 100px;
}
.end-intro {
  display: flex;
  justify-content: space-between;
}

.footer h1 {
  padding-bottom: 20px;
}
.social-icon i {
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 30px;
  font-size: 35px;
  color: #fff;
}

.copyright {
  padding: 10px;
  padding-bottom: 25px;
}
.credits {
  padding-top: 10px;
  padding-bottom: 15px;
}

.credits a{
  color: floralwhite;
  font-size: 13px;
}

@media screen and (max-width: 720px) {
  .end-intro {
    flex-direction: column;
  }
  .footer h1 {
    font-size: 32px;
  }
  .copyright {
    font-size: 15px;
  }
  .credits {
    font-size: 15px;
  }
}

@media screen and (max-width: 520px) {
  .social-icon i {
    margin: 20px;
    font-size: 32px;
  }
}

@media screen and (max-width: 480px) {
  .social-icon i {
    margin: 10px;
    font-size: 30px;
  }
}

@media screen and (max-width: 300px) {
  .social-icon i {
    margin: 8px;
    font-size: 22px;
  }
}

@media screen and (max-width: 230px) {
  .social-icon i {
    margin: 8px;
    font-size: 15px;
  }
}
