.t-header{
  background-color: #00745f;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.t-header p{
  margin-top: 20px;
}
.t-header h3{
  margin: 50px;
}

.back-end {
  text-align: center;
  padding: 20px;
}
.back-end h2{
  margin: 15px;
}
.back-end img {
  flex-wrap: wrap;
  width: 150px;
  margin: 35px;
}
