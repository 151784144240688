.l {
  
  background-color: gainsboro;
  margin: 10px;
  padding: 10px;
  width: 100wh;
}

.l h4 {
  text-align: center;
  padding-top: 20px;
  color: green;
  font-family: Verdana, Tahoma, sans-serif;
}

.l form {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

input {
  width: 300px;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  height: 50%;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  padding-left: 10px;
}
.l button {
  border-radius: 25px;
  width: 100px;
  padding: 10px;
  color: #fff;
  font-weight: 600;
}

@media (max-width: 720px) {
}
