.h {
  justify-content: center;
}
.h-main {
  background-color: #00745f;
  display: flex;
}
.h-info {
  padding: 0 100px;
}
.pro {
  justify-content: center;
  text-align: center;
  align-items: center;
}
.pro img {
  width: 60%;
  margin-top: 50px;
  height: auto;
}

.h h1 {
  color: #fff;
  margin: 10px;
  margin-top: 150px;
  font-size: 45px;
  font-weight: 400;
}

.h samp {
  margin: 15px;
  color: #fff;
   
}

.h button {
  padding: 15px 35px;
  border-radius: 35px;
  margin-top: 40px;
  margin-bottom: 20px;
  background-color: rgb(185, 185, 38);
  font-size: 15px;
  border: none;
  cursor: context-menu;
}

.clients {
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.clients img {
  margin: 30px;
  width: 120px;
  height: 40px;
}

@media (max-width: 1080px) {
  .h-main {
    flex-direction: column-reverse;
  }
  .h-info {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
  }

  .h h1 {
    margin-top: 20px;
  }
}
@media (max-width: 720px) {
  .h-main {
    flex-direction: column-reverse;
  }

  .h h1 {
    margin-top: 20px;
  }
}
