.service {
  justify-content: center;
  text-align: center;
}

.s-hearder {
  background-color: #00745F;
}

.s-hearder h2{
  padding: 50px;
  color: aliceblue;
  font-weight: 300;
}

.service p {
  text-align: justify;
  margin:20px 75px;
}

@media (max-width:520px) {
  .service p {
    text-align: justify;
    margin:20px 25px;
  }
  
}