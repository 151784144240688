.contact {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.c-header {
  background-color: #00745f;
}

.c-header h3 {
  padding: 15px;
  color: antiquewhite;
}

.c-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.c-header p {
  color: aliceblue;
  padding: 25px;
}

.r-1 {
  background-color: rgb(241, 240, 237);
  border-radius: 5px;
  padding: 35px 85px;
  margin: 20px;
}

.c-right i {
  font-size: 35px;
  margin: 25px;
}

.fa-map-marker-alt {
  color: blue;
}

.fa-envelope {
  color: red;
}

.fa-phone-square {
  color: green;
}

.contact-disci {
  margin: 30px 65px;
  text-align: justify;
}
.c-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 25px 100px;
}
.c {
  background-color: antiquewhite;
  border-radius: 5px;
  padding: 35px 150px;
  margin: 25px;
}
.c-Location {
  margin: 20px;
}
.c-Location p {
  margin: 20px 50px;
}

.c-Location img {
  margin: 20px;
}

@media (max-width: 1080px) {
  .contact-icon {
    flex-direction: column;
  }

  .c-right {
    flex-direction: column;
    padding: 20px 80px;
  }

  .r-1 {
    margin: 25px;
  }

  .c-Location p {
    margin: 20px 50px;
  }
}

@media (max-width: 540px) {
  .c-right {
    flex-direction: column;
    padding: 20px 40px;
  }

  .contact-disci {
    margin: 30px 25px;
  }

  .r-1 {
    padding: 35px 5px;
  }
}
